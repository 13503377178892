import store from '../store';
const requireAuth = true;
const requireCommunityAuth = true;
const useMobile = true;
const useScrollTo = true;
const isCommunity = true;
const isCashmall = true;
const useSubHeader = true;
const error = true;
const log = {
  main: {
    menuid1: 'MAIN',
  },
  cashmall: {
    menuid1: 'CASHMALL',
  },
  community: {
    menuid1: 'COMMUNITY',
    menuid2: '01',
  },
  company: {
    menuid1: 'COMPANY',
  },
  class: {
    menuid1: 'HAKSUL',
    menuid2: '42',
  },
  news: {
    menuid1: 'HAKSUL',
    menuid2: '43',
  },
  mypage: {
    menuid1: 'MYPAGE',
  },
  junggo: {
    menuid1: 'JUNGGO',
    menuid2: '65',
  },
};

const routes = [
  {
    path: '/',
    props: route => ({ isHome: true, type: route.query.type, subCode: route.query.subCode }),
    meta: { useMobile },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout'),
    children: [
      {
        path: 'index.do',
        alias: '',
        name: 'Home',
        meta: { useMobile, ...log.main, title: '닥플 | 대한민국 의사 커뮤니티 Docple' },
        props: route => ({ subCode: route.query.subCode, type: route.query.type, code: route.query.code }),
        component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
      },
    ],
  },
  // 로그인
  {
    path: '/login/',
    props: () => ({ isHome: true }),
    meta: { useMobile },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/JoinLayout'),
    children: [
      {
        path: '',
        meta: { useMobile, title: '닥플 | 로그인' },
        name: 'Login',
        props: route => ({ redirect: route.query.redirect, jstl: route.query.jstl }),
        component: () => import(/* webpackChunkName: "home" */ '../views/member/Login'),
      },
    ],
  },
  // 로그인
  {
    path: '/memberLogin.do/',
    meta: { useMobile },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/JoinLayout'),
    children: [
      {
        path: '',
        meta: { useMobile },
        name: 'Login.do',
        props: route => ({ redirect: route.query.redirect, jstl: route.query.jstl }),
        component: () => import(/* webpackChunkName: "home" */ '../views/member/Login'),
      },
    ],
  },
  {
    path: '/findIdPw/',
    meta: { useMobile },
    component: () => import(/* webpackChunkName: "login" */ '../components/layout/JoinLayout'),
    children: [
      {
        path: '',
        name: 'FindIdPassword',
        meta: { useMobile, title: '닥플 | 아이디 패스워드 찾기' },
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "login" */ '../views/member/FindIdPw'),
      },
    ],
  },
  // 회원가입
  {
    path: '/join/',
    meta: { useMobile },
    props: true,
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/JoinLayout'),
    children: [
      // 회원가입
      {
        path: '',
        name: 'JoinMember',
        meta: { useMobile, title: '닥플 | 회원 가입' },
        component: () => import(/* webpackChunkName: "member" */ '../views/member/JoinTypeSelect'),
      },
      // 약관동의
      {
        path: 'agree',
        name: 'JoinAgree',
        props: route => ({ type: route.query.type }),
        meta: { useMobile },
        component: () => import(/* webpackChunkName: "member" */ '../views/member/JoinAgree'),
        beforeEnter: (to, from, next) => {
          // 특정 쿼리 파라미터 체크
          if (to.query.type === 'nomal') {
            // 다른 경로로 리다이렉트
            next({ path: '/client/auth' });
          } else {
            next();
          }
        },
      },
      // 휴대폰인증
      {
        path: 'identify',
        name: 'JoinIdentify',
        props: route => ({ type: route.query.type, u: route.query.u }),
        meta: { useMobile },
        component: () => import(/* webpackChunkName: "member" */ '../views/member/JoinIdentify'),
      },
      // 정보입력
      {
        path: 'input',
        name: 'JoinBaseInput',
        props: route => ({ type: route.query.type, u: route.query.u }),
        meta: { useMobile },
        component: () => import(/* webpackChunkName: "member" */ '../views/member/JoinBaseInput'),
      },
      // 완료
      {
        path: 'success',
        name: 'JoinSuccess',
        meta: { useMobile, title: '닥플 | 회원 가입 완료' },
        component: () => import(/* webpackChunkName: "member" */ '../views/member/JoinSuccess'),
      },
    ],
  },
  // 로그인시 변경된 약관동의 페이지
  {
    path: '/terms/',
    meta: { useMobile },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout.vue'),
    children: [
      {
        path: 'agree',
        name: 'TermsAgree',
        meta: { useMobile, useSubHeader },
        component: () => import(/* webpackChunkName: "terms" */ '../views/terms/TermsAgree'),
      },
    ],
  },
  // 검색
  {
    path: '/unitedSearch/',
    meta: { useMobile, requireAuth },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'UnitedSearch',
        meta: { useMobile },
        props: route => ({ keyword: route.query.keyword }),
        component: () => import(/* webpackChunkName: "search" */ '../views/search/UnitedSearch'),
      },
    ],
  },

  // 상단 전체 검색
  {
    path: '/TotalSearch/',
    meta: { useMobile, useSubHeader },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'TotalSearch',
        meta: { useMobile, useSubHeader },
        props: route => ({ keyword: route.query.keyword }),
        component: () => import(/* webpackChunkName: "search" */ '../views/search/TotalSearch'),
      },
    ],
  },
  {
    path: '/search/',
    meta: { useMobile, useSubHeader },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'search',
        meta: { useMobile, useSubHeader },
        props: route => ({ keyword: route.query.keyword }),
        component: () => import(/* webpackChunkName: "search" */ '../views/search/TotalSearch'),
      },
      {
        path: ':type',
        name: 'searchTypeResult',
        meta: { useMobile, useSubHeader },
        props: route => ({ keyword: route.query.keyword, type: route.params.type }),
        component: () => import(/* webpackChunkName: "search" */ '../views/search/SearchTypeResult'),
      },
    ],
  },
  {
    // 커뮤니티
    path: '/settings/',
    meta: { useMobile, requireAuth },
    props: { isCommunity },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/CommunityLayout'),
    children: [
      // 고정필명, 커뮤니티 패스워드, 병원정보 등 필요 정보 없을때 들어가는 페이지
      {
        path: 'editMember',
        name: 'CommunityEditMember',
        meta: { useMobile, requireAuth, ...log.community },
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityEditMember.vue'),
      },
    ],
  },
  {
    // 커뮤니티
    path: '/community/',
    meta: { useMobile, requireAuth },
    props: { isCommunity },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/CommunityLayout'),
    children: [
      // 커뮤니티 비밀번호 입력
      {
        path: 'password',
        name: 'CommunityPassword',
        meta: { useMobile, requireAuth, ...log.community, title: '닥플 | 커뮤니티 비밀번호' },
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityPassword.vue'),
      },
      // 목록
      {
        path: 'list',
        name: 'BoardList',
        alias: '',
        meta: { useMobile, requireAuth, requireCommunityAuth, ...log.community, title: '닥플 | 커뮤니티' },
        props: route => ({
          curPage: parseInt(route.query.curPage),
          subCode: route.query.subCode?.trim(),
          type: route.query.type?.trim(),
          searchOption: route.query.searchOption,
          keyword: route.query.keyword,
          pageScale: route.query.pageScale,
          nsfwYN: route.query.nsfwYN,
          myList: route.query.myList,
          divPage: route.query.divPage,
        }),
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityList'),
      },
      // 목록 .do 대응
      {
        path: 'BoardListPage.do',
        name: 'BoardList.do',
        meta: { useMobile, requireAuth, requireCommunityAuth, ...log.community },
        props: route => ({
          curPage: parseInt(route.query.curPage),
          subCode: route.query.sub_code?.trim(),
          searchOption: route.query.searchOption,
          keyword: route.query.keyword,
          pageScale: route.query.pageScale,
          nsfwYN: route.query.nsfwYN,
          myList: route.query.myList == 'my' ? 'Y' : 'N',
          divPage: route.query.divPage,
        }),
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityList'),
      },
      // 글쓰기
      {
        path: 'write',
        name: 'BoardWrite',
        meta: { useMobile, useSubHeader, useScrollTo, requireCommunityAuth, requireAuth, ...log.community, title: '닥플 | 커뮤니티 글쓰기' },
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityWrite'),
      },
      // 글수정
      {
        path: 'write/:id',
        name: 'BoardEdit',
        meta: { useMobile, useSubHeader, useScrollTo, requireCommunityAuth, requireAuth, ...log.community },
        props: route => ({ id: route.params.id, title: '닥플 | 커뮤니티 글 수정' }),
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityWrite'),
      },
      // 글 상세 .do 대응
      {
        path: 'BoardView.do',
        name: 'BoardDetail.do',
        meta: { useMobile, requireAuth, requireCommunityAuth, ...log.community },
        props: route => ({ id: route.query.bid, subCode: route.query.sub_code?.trim() }),
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityDetail'),
      },
      // 글 상세
      {
        path: ':id',
        name: 'BoardDetail',
        meta: { useMobile, requireAuth, requireCommunityAuth, ...log.community, title: '닥플 | 커뮤니티 상세' },
        props: route => ({
          id: route.params.id,
          subCode: route.query.subCode?.trim(),
          curPage: parseInt(route.query.curPage),
          searchOption: route.query.searchOption,
          keyword: route.query.keyword,
          pageScale: route.query.pageScale,
          nsfwYN: route.query.nsfwYN,
          myList: route.query.myList,
          divPage: route.query.divPage,
        }),
        component: () => import(/* webpackChunkName: "home" */ '../views/community/CommunityDetail'),
      },
      // 글의 댓글 목록
      {
        path: ':id/comment',
        name: 'BoardComment',
        meta: { useMobile, useSubHeader, requireAuth, requireCommunityAuth, ...log.community },
        props: route => ({ id: route.params.id }),
        component: () => import(/* webpackChunkName: "home" */ '../views/community/Comment'),
      },
    ],
  },
  {
    // 댓글
    path: '/comment/',
    meta: { useMobile, requireAuth },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/BlankLayout.vue'),
    children: [
      // 댓글 수정
      {
        path: ':rBid/modify',
        meta: { useMobile, requireAuth },
        props: route => ({ rBid: route.params.rBid }),
        component: () => import(/* webpackChunkName: "home" */ '../views/popup/CommentModifyPopup.vue'),
      },
      // 답글 쓰기
      {
        path: ':rBid/answer',
        meta: { useMobile, requireAuth },
        props: route => ({ rBid: route.params.rBid }),
        component: () => import(/* webpackChunkName: "home" */ '../views/popup/CommentAnswerPopup.vue'),
      },
    ],
  },
  {
    // 중고장터
    path: '/shopping/',
    meta: { useMobile, requireAuth },
    props: () => ({ isCommunity: true }),
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout'),
    children: [
      // 중고장터 목록
      {
        path: 'list',
        name: 'JunggoList',
        alias: '',
        meta: { useMobile, ...log.junggo, title: '닥플 | 중고 장터' },
        component: () => import(/* webpackChunkName: "junggo" */ '../views/shopping/JunggoList'),
      },
      // 중고장터 목록
      {
        path: 'junggoIndex.do',
        name: 'JunggoList.do',
        meta: { useMobile, ...log.junggo },
        props: route => ({
          curPage: route.query.curPage,
          searchOption: route.query.searchOption,
          keyword: route.query.keyword,
          pageScale: route.query.pageScale,
          myListYn: route.query.myListYn,
          used: route.query.used,
          sido: route.query.sido,
          item: route.query.item,
        }),
        component: () => import(/* webpackChunkName: "junggo" */ '../views/shopping/JunggoList'),
      },
      // 중고장터 글쓰기
      // {
      //   path: 'write',
      //   name: 'JunggoWrite',
      //   meta: { useMobile, ...log.junggo },
      //   component: () => import(/* webpackChunkName: "junggo" */ '../views/shopping/JunggoWrite'),
      // },
      // 중고장터 글수정
      // {
      //   path: 'write/:id',
      //   name: 'JunggoEdit',
      //   meta: { useMobile, ...log.junggo },
      //   props: route => ({ id: route.params.id }),
      //   component: () => import(/* webpackChunkName: "junggo" */ '../views/shopping/JunggoWrite'),
      // },
      // 중고장터 글상세
      {
        path: ':bid',
        name: 'JunggoDetail',
        meta: { useMobile, ...log.junggo, title: '닥플 | 중고 장터 상세' },
        props: route => ({ id: route.params.bid }),
        component: () => import(/* webpackChunkName: "junggo" */ '../views/shopping/JunggoDetail'),
      },
    ],
  },
  {
    // 회사소개
    path: '/company/',
    meta: { useMobile, title: '닥플 | 회사 소개' },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/FullLayout'),
    children: [
      // 광고문의
      {
        path: 'adbiz',
        name: 'AdBusiness',
        meta: { useMobile, title: '닥플 | 광고 문의' },
        component: () => import(/* webpackChunkName: "company" */ '../views/company/AdBusiness.vue'),
      },
      // 광고문의
      {
        path: 'Adbiz.do',
        name: 'AdBusiness.do',
        meta: { useMobile },
        component: () => import(/* webpackChunkName: "company" */ '../views/company/AdBusiness.vue'),
      },
      {
        path: 'terms',
        name: 'Terms',
        meta: { useMobile, title: '닥플 | 이용약관' },
        props: route => ({ menu: route.query.menu }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/CompanyTerms.vue'),
      },
      // 이용약관
      {
        path: 'totalTap.do',
        name: 'Terms.do',
        meta: { useMobile },
        props: route => ({ menu: route.query.menuName }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/CompanyTerms.vue'),
      },
      // 정책
      {
        path: 'policy',
        name: 'Policy',
        meta: { useMobile, title: '닥플 | 이용 정책' },
        component: () => import(/* webpackChunkName: "company" */ '../views/company/CompanyPolicy.vue'),
      },
      // 정책
      {
        path: 'Policy.do',
        name: 'Policy.do',
        meta: { useMobile },
        component: () => import(/* webpackChunkName: "company" */ '../views/company/CompanyPolicy.vue'),
      },
      // 고객센터
      {
        path: 'cs',
        name: 'CustomerServiceList',
        meta: { useMobile, title: '닥플 | 고객센터' },
        props: route => ({ type: route.query.type || store.getters.isMobile ? route.query.type : 'notice', subCode: route.query.subCode }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/cs/CustomerService.vue'),
      },
      // 고객센터
      {
        path: 'Cs.do',
        name: 'CustomerServiceList.do',
        meta: { useMobile },
        props: route => ({ type: route.query.type || store.getters.isMobile ? route.query.type : 'notice', subCode: route.query.subCode }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/cs/CustomerService.vue'),
      },
      // 고객센터 글 작성
      {
        path: 'cs/write',
        name: 'CustomerServiceWrite',
        meta: { useMobile, requireAuth },
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/cs/CustomerServiceWrite.vue'),
      },
      // 고객센터 글 수정
      {
        path: 'cs/write/:id',
        name: 'CustomerServiceEdit',
        meta: { useMobile, requireAuth },
        props: route => ({ type: route.query.type, id: route.params.id }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/cs/CustomerServiceWrite.vue'),
      },

      // 고객센터 글 상세
      {
        path: 'cs/:id',
        name: 'CustomerServiceDetail',
        meta: { useMobile, requireAuth },
        props: route => ({ type: route.query.type, id: route.params.id }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/cs/CustomerServiceDetail.vue'),
      },
      // 고객센터 글 상세
      {
        path: 'cs/:type/:id',
        name: 'CustomerServiceDetailByParams',
        meta: { useMobile, requireAuth },
        props: route => ({ id: route.params.id, type: route.params.type }),
        component: () => import(/* webpackChunkName: "company" */ '../views/company/cs/CustomerServiceDetail.vue'),
      },
      // 사이트맵
      {
        path: 'sitemap',
        name: 'SiteMap',
        meta: { title: '사이트맵' },
        component: () => import(/* webpackChunkName: "company" */ '../views/company/SiteMap.vue'),
      },
      // 사이트맵
      {
        path: 'Sitemap.do',
        name: 'SiteMap.do',
        component: () => import(/* webpackChunkName: "company" */ '../views/company/SiteMap.vue'),
      },
    ],
  },
  // 임대분양
  {
    path: '/lease',
    meta: { requireAuth },
    props: () => ({ isCommunity: true }),
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout'),
    children: [
      // 임대분양 목록
      {
        path: '',
        meta: { title: '임대 분양' },
        name: 'LeaseList',
        component: () => import(/* webpackChunkName: "lease" */ '../views/lease/LeaseList'),
      },
      // // 임대분양 글쓰기
      // {
      //   path: 'write',
      //   name: 'LeaseWrite',
      //   component: () => import(/* webpackChunkName: "lease" */ '../views/lease/LeaseWrite'),
      // },
      // // 임대분양 글수정
      // {
      //   path: 'write/:id',
      //   name: 'LeaseEdit',
      //   props: route => ({ id: route.params.id }),
      //   component: () => import(/* webpackChunkName: "lease" */ '../views/lease/LeaseWrite'),
      // },
      // 임대분양 글상세
      {
        path: ':id',
        name: 'LeaseDetail',
        props: route => ({ id: route.params.id }),
        component: () => import(/* webpackChunkName: "lease" */ '../views/lease/LeaseDetail'),
      },
    ],
  },
  // 초빙
  {
    path: '/job',
    meta: { requireAuth },
    props: () => ({ isCommunity: true }),
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout'),
    children: [
      // 초빙 목록
      {
        path: '',
        name: 'JobList',
        meta: { title: '닥플 | 초빙 구직' },
        component: () => import(/* webpackChunkName: "job" */ '../views/job/JobList'),
      },
      // // 초빙 글쓰기
      // {
      //   path: 'write',
      //   name: 'JobWrite',
      //   component: () => import(/* webpackChunkName: "job" */ '../views/job/JobWrite'),
      // },
      // // 초빙 글수정
      // {
      //   path: 'write/:id',
      //   name: 'JobEdit',
      //   props: route => ({ id: route.params.id }),
      //   component: () => import(/* webpackChunkName: "job" */ '../views/job/JobWrite'),
      // },
      // 초빙 글상세
      {
        path: ':id',
        name: 'JobDetail',
        meta: { title: '닥플 | 초빙 구직 상세' },
        props: route => ({ id: route.params.id }),
        component: () => import(/* webpackChunkName: "job" */ '../views/job/JobDetail'),
      },
    ],
  },
  // 구직
  {
    path: '/jobPerson',
    meta: { requireAuth },
    props: () => ({ isCommunity: true }),
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout'),
    children: [
      // 구직 목록
      {
        path: '',
        name: 'JobPersonList',
        meta: { title: '닥플 | 인재 정보' },
        component: () => import(/* webpackChunkName: "job" */ '../views/job/JobPersonList'),
      },
      // // 구직 글쓰기
      // {
      //   path: 'write',
      //   name: 'JobPersonWrite',
      //   component: () => import(/* webpackChunkName: "job" */ '../views/job/JobPersonWrite'),
      // },
      // // 구직 글수정
      // {
      //   path: 'write/:id',
      //   name: 'JobPersonEdit',
      //   props: route => ({ id: route.params.id }),
      //   component: () => import(/* webpackChunkName: "job" */ '../views/job/JobPersonWrite'),
      // },
      // 구직 글상세
      {
        path: ':id',
        name: 'JobPersonDetail',
        meta: { title: '닥플 | 인재 정보 상세' },
        props: route => ({ id: route.params.id }),
        component: () => import(/* webpackChunkName: "job" */ '../views/job/JobPersonDetail'),
      },
    ],
  },
  {
    // 병원 경영
    path: '/business/',
    meta: { requireAuth },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout'),
    children: [
      // 상담 목록
      {
        path: 'consult',
        name: 'ConsultList',
        component: () => import(/* webpackChunkName: "business" */ '../views/business/consult/ConsultList'),
      },
      {
        path: 'consult/write',
        name: 'ConsultWrite',
        component: () => import(/* webpackChunkName: "business" */ '../views/business/consult/ConsultWrite'),
      },
      {
        path: 'consult/write/:id',
        name: 'ConsultEdit',
        component: () => import(/* webpackChunkName: "business" */ '../views/business/consult/ConsultWrite'),
      },
      {
        path: 'consult/:id',
        name: 'ConsultDetail',
        component: () => import(/* webpackChunkName: "business" */ '../views/business/consult/ConsultDetail'),
      },
      // 상담사 목록
      {
        path: 'consultant',
        name: 'ConsultantList',
        component: () => import(/* webpackChunkName: "business" */ '../views/business/consult/ConsultantList'),
      },
      // 상담사 프로필
      {
        path: 'consultant/:id',
        name: 'ConsultantDetail',
        component: () => import(/* webpackChunkName: "business" */ '../views/business/consult/ConsultantDetail'),
      },
    ],
  },
  {
    // 닥플 클래스
    path: '/haksul/',
    meta: { useMobile, title: '닥플 | 뉴스' },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/DefaultLayout'),
    children: [
      //     // 닥플클래스 신청하기
      //     {
      //       path: 'class/:id/apply',
      //       name: 'ClassApply',
      //       meta: { requireAuth, useSubHeader, useMobile, ...log.class },
      //       props: route => ({ id: route.params.id }),
      //       component: () => import(/* webpackChunkName: "class" */ '../views/haksul/class/ClassApply'),
      //     },
      //     // 닥플클래스 목록
      //     {
      //       path: 'class/list',
      //       alias: 'class',
      //       name: 'ClassList',
      //       meta: { requireAuth },
      //       component: () => import(/* webpackChunkName: "class" */ '../views/haksul/class/ClassList'),
      //     },
      //     // 닥플클래스 글쓰기
      //     {
      //       path: 'class/write',
      //       name: 'ClassWrite',
      //       meta: { requireAuth },
      //       component: () => import(/* webpackChunkName: "class" */ '../views/haksul/class/ClassWrite'),
      //     },
      //     // 닥플클래스 글수정
      //     {
      //       path: 'class/write/:id',
      //       name: 'ClassEdit',
      //       meta: { requireAuth },
      //       component: () => import(/* webpackChunkName: "class" */ '../views/haksul/class/ClassWrite'),
      //     },
      //     // 닥플클래스 글상세
      //     {
      //       path: 'class/:id',
      //       name: 'ClassDetail',
      //       meta: { useMobile, requireAuth, ...log.class },
      //       props: route => ({ id: route.params.id }),
      //       component: () => import(/* webpackChunkName: "class" */ '../views/haksul/class/ClassDetail'),
      //     },
      //     // 내가 주관하는 연수/강좌 목록
      //     {
      //       path: 'classOpenList',
      //       name: 'ClassOpenList',
      //       meta: { requireAuth },
      //       component: () => import(/* webpackChunkName: "class" */ '../views/haksul/class/ClassOpenList'),
      //     },
      //     // 내가 신청한 연수/강좌 목록
      //     {
      //       path: 'classApplyList',
      //       name: 'ClassApplyList',
      //       meta: { requireAuth },
      //       component: () => import(/* webpackChunkName: "class" */ '../views/haksul/class/ClassApplyList'),
      //     },
      // 뉴스
      {
        path: 'news',
        name: 'NewsList',
        meta: { useMobile, ...log.news, title: '닥플 | 뉴스' },
        component: () => import(/* webpackChunkName: "class" */ '../views/haksul/news/NewsList'),
      },
      // 뉴스 상세
      {
        path: 'news/:id',
        name: 'NewsDetail',
        meta: { useMobile, ...log.news, title: '닥플 | 뉴스 상세' },
        props: route => ({ id: route.params.id }),
        component: () => import(/* webpackChunkName: "class" */ '../views/haksul/news/NewsDetail'),
      },
    ],
  },
  {
    // 캐시몰
    path: '/cashmall/',
    meta: { useMobile, requireAuth },
    props: { isCashmall },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/CashMallLayout'),
    children: [
      // 캐시몰
      {
        path: '',
        name: 'CashMallList',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall, title: '닥플 | 캐시몰' },
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/CashMallList'),
      },
      // 캐시몰
      {
        path: 'mall.do',
        name: 'CashMallList.do',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall },
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/CashMallList'),
      },
      // 구매하기
      {
        path: 'payment',
        name: 'CashMallPayment',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall, title: '닥플 | 캐시몰 구매하기' },
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/CashMallPayment'),
      },
      // 구매하기 완료 페이지
      {
        path: 'result',
        name: 'PaymentResult',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall, title: '닥플 | 캐시몰 구매 완료' },
        props: route => ({ buyIdx: route.query.buyIdx }),
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/PaymentResult'),
      },
      {
        path: 'giftcash',
        name: 'GiftCash',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall, title: '닥플 | 닥플캐시 선물하기' },
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/GiftCash'),
      },
      // 캐시몰 my 캐시, my 쇼핑
      {
        path: 'mycashmall',
        name: 'MyCashMall',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall, title: '닥플 | My 캐시' },
        props: route => ({ info: route.query.info }),
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/MyCashMall'),
      },
      // 캐시몰 상품상세
      {
        path: 'mallView.do',
        name: 'CashMallDetail.do',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall },
        props: route => ({ id: route.query.goodsId }),
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/CashMallDetail'),
      },
      // 캐시몰 상품상세
      {
        path: ':id',
        name: 'CashMallDetail',
        meta: { useMobile, useSubHeader, requireAuth, ...log.cashmall, title: '닥플 | 캐시몰 상세' },
        props: route => ({ id: route.params.id }),
        component: () => import(/* webpackChunkName: "cashmall" */ '../views/cashmall/CashMallDetail'),
      },
    ],
  },
  {
    // 마이페이지
    path: '/mypage/',
    meta: { useMobile, requireAuth },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/FullLayout.vue'),
    children: [
      // 마이페이지
      {
        path: 'index',
        alias: '',
        meta: { useMobile, useSubHeader, requireAuth, ...log.mypage, title: '닥플 | 마이페이지' },
        name: 'MyPageIndex',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Mypage.vue'),
      },
      // 마이페이지
      {
        path: 'index.do',
        alias: '',
        meta: { useMobile, useSubHeader, requireAuth, ...log.mypage, title: '닥플 | 마이페이자' },
        name: 'MyPageIndex.do',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/Mypage.vue'),
      },
      // 정보수정
      {
        path: 'modify',
        meta: { useMobile, requireAuth, ...log.mypage, title: '닥플 | 정보 수정' },
        name: 'MyInfoModify',
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MemberInfoModify.vue'),
      },
      // 정보수정
      {
        path: 'passwordCheckInfo.do',
        meta: { useMobile, requireAuth, ...log.mypage },
        name: 'MyInfoModifyCheckPassword.do',
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MemberInfoModify.vue'),
      },
      // 정보수정
      {
        path: 'Modify.do',
        meta: { useMobile, requireAuth, ...log.mypage },
        name: 'MyInfoModify.do',
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MemberInfoModify.vue'),
      },
      // 스크랩
      {
        path: 'scrap',
        meta: { useMobile, requireAuth, ...log.mypage, title: '닥플 | 스크랩' },
        name: 'MyScarpList',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/scrap/ScrapList.vue'),
      },
      // 스크랩
      {
        path: 'Scrap.do',
        meta: { useMobile, requireAuth, ...log.mypage },
        name: 'MyScarpList.do',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/scrap/ScrapList.vue'),
      },
      {
        path: 'scrap/:id',
        meta: { useMobile, requireAuth, ...log.mypage, title: '닥플 | 스크랩 상세' },
        name: 'MyScarpDetail',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/scrap/ScrapDetail.vue'),
      },
      // 쪽지
      {
        path: 'note',
        meta: { useMobile, requireAuth, title: '닥플 | 쪽지함' },
        name: 'MyNoteList',
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/note/NoteList.vue'),
      },
      // 쪽지
      {
        path: 'Note.do',
        meta: { useMobile, requireAuth },
        name: 'MyNoteList.do',
        props: route => ({ type: route.query.type }),
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/note/NoteList.vue'),
      },
      // 쪽지 대상 찾기
      {
        path: 'note/findMember',
        name: 'NoteFindMember',
        meta: { useMobile, requireAuth },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/note/NoteFindMember.vue'),
      },
      // 쪽지 쓰기
      {
        path: 'note/write',
        name: 'NoteWrite',
        meta: { useMobile, requireAuth },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/note/NoteWrite.vue'),
      },
      // 쪽지 상세
      // {
      //   path: 'note/:bid',
      //   name: 'MyNoteDetail',
      //   meta: { useMobile, requireAuth },
      //   props: route => ({ bid: route.params.bid, case: route.query.case }),
      //   component: () => import('../views/mypage/note/NoteDetail.vue'),
      // },
      // 활동내역
      {
        path: 'board',
        name: 'MyBoard',
        meta: { useMobile, requireAuth, ...log.mypage, title: '닥플 | 나의 활동내역' },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyBoard.vue'),
      },
      // 활동내역
      {
        path: 'MyBoard.do',
        name: 'MyBoard.do',
        meta: { useMobile, requireAuth, ...log.mypage },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyBoard.vue'),
      },
      // 참여 클래스
      {
        path: 'class',
        meta: { useMobile, requireAuth, ...log.mypage },
        name: 'MyClassList',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyClass.vue'),
      },
      // 내 댓글 목록
      {
        path: 'comment',
        meta: { useMobile, requireAuth, ...log.mypage },
        name: 'MyCommentList',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MyComment.vue'),
      },
      // // 쇼핑
      // {
      //   path: 'shopping',
      //   name: 'ShoppingList',
      //   meta: { useMobile, requireAuth },
      //   component: () => import('../views/mypage/ShoppingList.vue'),
      // },
      // 회원검색
      {
        path: 'search',
        name: 'MemberSearch',
        meta: { useMobile, requireAuth, title: '닥플 | 회원 검색' },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MemberSearch.vue'),
      },
      // 회원검색
      {
        path: 'MemberSearch.do',
        name: 'MemberSearch.do',
        meta: { useMobile, requireAuth },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MemberSearch.vue'),
      },
      // 회원탈퇴
      {
        path: 'delete',
        name: 'MemberDelete',
        meta: { useMobile, requireAuth, ...log.mypage, title: '닥플 | 회원 탈퇴' },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MemberDelete.vue'),
      },
      // 회원탈퇴
      {
        path: 'MemberDel.do',
        name: 'MemberDelete.do',
        meta: { useMobile, requireAuth, ...log.mypage },
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/MemberDelete.vue'),
      },
    ],
  },
  // 무찌 찬성/반대 리스트 팝업
  {
    path: '/recommList/:bid',
    meta: { useMobile, requireAuth },
    props: route => ({ id: route.params.bid, grpCode: route.query.grpCode, type: route.query.type }),
    component: () => import(/* webpackChunkName: "home" */ '../views/popup/RecommList.vue'),
  },
  // 다음 주소 팝업
  {
    path: '/daumpost/',
    meta: { useMobile },
    component: () => import('../views/popup/DaumPostPopup.vue'),
  },
  // 수련병원 팝업
  {
    path: '/schHospital/',
    meta: { useMobile },
    component: () => import('../views/popup/SearchHospitalPopup.vue'),
  },
  // 커뮤니티 게시판 운영방침
  {
    path: '/communityRule/',
    meta: { useMobile, requireAuth },
    component: () => import('../components/layout/DefaultLayout'),
    children: [
      {
        path: '',
        name: 'CommunityRulePopup',
        meta: { title: '닥플 | 커뮤니티 운영 지침' },
        component: () => import('../views/popup/CommunityRulePopup.vue'),
      },
    ],
  },
  {
    // 진료실
    path: '/hospital/',
    meta: { useMobile, requireAuth },
    props: { isCommunity },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/FullLayout.vue'),
    children: [
      {
        path: '',
        name: 'TreatmentRoom',
        component: () => import(/* webpackChunkName: "home" */ '../views/hospital/TreatmentRoom.vue'),
      },
    ],
  },
  {
    // 의약품몰
    path: '/mall/',
    meta: { useMobile, requireAuth },
    props: { isCommunity },
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/FullLayout.vue'),
    children: [
      {
        path: '',
        name: 'MallIntro',
        meta: { title: '닥플 | 의약품 몰' },
        component: () => import(/* webpackChunkName: "home" */ '../views/mall/Intro.vue'),
      },
    ],
  },
  {
    // 파트너즈
    path: '/partners/',
    meta: { useMobile, requireAuth },
    props: () => ({}),
    component: () => import(/* webpackChunkName: "default" */ '../components/layout/FullLayout.vue'),
    children: [
      {
        path: '',
        name: 'PartnersFrame',
        meta: { title: '닥플 | 파트너즈' },
        component: () => import(/* webpackChunkName: "home" */ '../views/partners/PartnersFrame.vue'),
      },
    ],
  },
  // 스크랩하기 팝업
  {
    path: '/scrap/',
    meta: { useMobile, requireAuth },
    props: route => ({ id: route.query.bid, grpCode: route.query.grpCode, url: route.query.url }),
    component: () => import('../views/popup/ScrapPopup.vue'),
  },
  // 스크랩 폴더관리
  {
    path: '/scrapFolder/',
    meta: { useMobile, requireAuth },
    component: () => import('../views/popup/ScrapFolderPopup.vue'),
  },
  // 무찌 공유하기 팝업
  {
    path: '/muzziShare/',
    meta: { useMobile, requireAuth },
    component: () => import('../views/popup/MuzziSharePopup.vue'),
  },
  // SNS 공유하기 팝업
  {
    path: '/snsShare/',
    meta: { useMobile, requireAuth },
    component: () => import('../views/popup/SnsSharePopup.vue'),
  },
  // 쪽지 상세
  {
    path: '/note/:bid',
    name: 'NoteDetail',
    meta: { useMobile, requireAuth, title: '닥플 | 받은 쪽지' },
    props: route => ({ bid: route.params.bid, case: route.query.case }),
    component: () => import('../views/popup/NoteDetailPopup.vue'),
  },
  // 쪽지 보내기용 회원검색 팝업
  {
    path: '/noteFindMember/',
    meta: { useMobile, requireAuth },
    component: () => import('../views/popup/NoteFindMemberPopup.vue'),
  },
  // 쪽지 보내기 팝업
  {
    path: '/noteWrite/:uid',
    meta: { useMobile, requireAuth },
    props: route => ({ uid: route.params.uid }),
    component: () => import('../views/popup/NoteWritePopup.vue'),
  },
  // 초빙구직 이메일 팝업
  {
    path: '/jobEmail',
    meta: { useMobile },
    props: route => ({ type: route.query.type }),
    component: () => import('../views/popup/JobEmailPopup.vue'),
  },
  // 본인인증 결과페이지
  {
    path: '/memberIdentify',
    meta: { useMobile },
    component: () => import('../views/member/GetIdentifyInfo.vue'),
  },
  // legacy (jstl) 로그아웃용
  {
    path: '/logout.do',
    meta: { useMobile },
    component: () => import('../views/legacy/Logout.vue'),
  },
  {
    // 마이페이지
    path: '/mobile/',
    meta: { useMobile, requireAuth, error, title: '닥플 | 모바일 마이페이지' },
    component: () => import('../components/layout/DefaultLayout.vue'),
    children: [
      // 모바일 페이지 준비중
      {
        path: 'notFound',
        meta: { useMobile, error },
        component: () => import('../views/error/MNotFound'),
      },
    ],
  },
  // 에러 페이지 404
  {
    path: '/error',
    meta: { useMobile, error },
    component: () => import('../views/error/404.vue'),
  },
  // 에러 페이지 429
  {
    path: '/danger',
    meta: { useMobile, error },
    component: () => import('../views/error/429.vue'),
  },
  {
    path: '/temp-error',
    name: 'TempError',
    meta: { useMobile, error },
    component: () => import('../views/error/TemporaryError.vue'),
  },
  // 시스템 공지
  // {
  //   path: '/notice',
  //   meta: { useMobile, error },
  //   component: () => import('../views/error/SystemNotice.vue'),
  // },
  // Rx
  {
    path: '/m-rx',
    meta: { useMobile, title: '닥플 | 모바일 RX' },
    component: () => import('../views/rx/Rx.vue'),
  },
  // 에러 페이지 404
  {
    path: '/*',
    meta: { useMobile },
    component: () => import('../views/error/404.vue'),
  },
];

export default routes;
